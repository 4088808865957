import type { ExtendedDepositBonus, DepositBonus } from '../../types'

interface TooltipParams {
  isStepBonus?: boolean
}
export function useDepositTooltip(
  depositBonus: Ref<ExtendedDepositBonus | DepositBonus>,
  { isStepBonus = false }: TooltipParams = {},
) {
  const { t } = useI18n()

  const {
    minDepositAmount,
    maxDefaultBonusAmountWithCurrency,
    maxFreespinBonusFiatAmount,
    isMultiBonus,
    cashDepositBonusSettings,
    freebetDepositBonusSettings,
    freespinDepositBonusSettings,
    reloadDepositBonusSettings,
    freespinAmountInAppCurrency,
    bonusesCurrencies,
    filteredMultiBonuses,
  } = useDepositBonus(depositBonus)

  const maxRewardBlocks = computed(() => {
    if (
      !maxDefaultBonusAmountWithCurrency.value &&
      !maxFreespinBonusFiatAmount.value
    ) {
      return []
    }

    return [
      ...(maxDefaultBonusAmountWithCurrency.value
        ? [
            {
              termin: t('bonuses.maxDepositRewardAmount'),
              description: maxDefaultBonusAmountWithCurrency.value,
            },
          ]
        : []),
      ...(maxFreespinBonusFiatAmount.value
        ? [
            {
              termin: t('bonuses.maxFreespinRewardAmount'),
              description: maxFreespinBonusFiatAmount.value,
            },
          ]
        : []),
    ]
  })

  const depositBonusRollingSettings = computed(() => {
    const defaultRolling = {
      rollingMultiplier: '0',
      rollingPeriodInHours: 0,
      rollingMinBetRate: null,
      rollingMaxBetRate: null,
      rollingCasinoCategoryIds: null,
    }

    if (isMultiBonus.value) return defaultRolling

    /*
        из-за того, что некоторые поля, отвечающие за обкатку
        у програм внутри /bonus-for-deposit/find отличаются
        приходится обрабатывать отдельно каждый случай
        пока бэк не приведет все к единому виду
      */
    if (cashDepositBonusSettings.value) {
      const {
        rollingMultiplier,
        rollingMinBetRate,
        rollingMaxBetRate,
        rollingPeriodInHours,
        rollingCasinoCategoryIds,
      } = cashDepositBonusSettings.value

      return {
        rollingMultiplier,
        rollingPeriodInHours,
        rollingMinBetRate,
        rollingMaxBetRate,
        rollingCasinoCategoryIds,
      }
    }
    if (freebetDepositBonusSettings.value) {
      const {
        rollingMultiplier = '',
        rollingPeriodInHours = null,
        rollingMinBetRate = null,
        rollingMaxBetRate = null,
        rollingCasinoCategoryIds = null,
      } = freebetDepositBonusSettings.value.freebetRollingSettings ?? {}

      return {
        rollingMultiplier,
        rollingPeriodInHours,
        rollingMinBetRate,
        rollingMaxBetRate,
        rollingCasinoCategoryIds,
      }
    }
    if (freespinDepositBonusSettings.value) {
      const {
        rollingMultiplier,
        rollingMinBetRate,
        rollingMaxBetRate,
        rollingDurationPeriodInHours: rollingPeriodInHours,
        rollingCasinoCategoryIds,
      } = freespinDepositBonusSettings.value

      return {
        rollingMultiplier,
        rollingPeriodInHours,
        rollingMinBetRate,
        rollingMaxBetRate,
        rollingCasinoCategoryIds,
      }
    }

    return defaultRolling
  })

  const isParametersBlockVisible = computed(
    () =>
      freespinAmountInAppCurrency.value ||
      cashDepositBonusSettings.value ||
      reloadDepositBonusSettings.value,
  )

  const reloadInterval = computed(() => {
    const interval =
      reloadDepositBonusSettings.value?.bonusRepeatIntervalInHours
    if (!interval) return '-'

    const hours = interval % 24
    const days = (interval - hours) / 24

    if (hours === 0)
      return t('bonuses.repeatCount', { period: t('bonuses.days', days) })

    if (days === 0)
      return t('bonuses.repeatCount', { period: t('bonuses.hours', hours) })

    const periodsCombinations = `${t('bonuses.days', days)} ${t(
      'bonuses.hours',
      hours,
    )}`
    return t('bonuses.repeatCount', { period: periodsCombinations })
  })

  const reloadParameters = computed(() => {
    if (!reloadDepositBonusSettings.value) return []

    return [
      ...(!isStepBonus
        ? [
            {
              termin: t('bonuses.tooltipRegularRewardCurrency'),
              description: bonusesCurrencies.value.reload.code,
            },
          ]
        : []),
      {
        termin: t('bonuses.reloadPeriod'),
        description: reloadInterval.value,
      },
    ]
  })

  return {
    maxRewardBlocks,
    depositBonusRollingSettings,
    isParametersBlockVisible,
    reloadParameters,
    minDepositAmount,
    freespinAmountInAppCurrency,
    isMultiBonus,
    filteredMultiBonuses,
  }
}

<template>
  <BaseTooltipModal
    v-if="depositBonus"
    :link="readMoreUrl"
    @close="handleClose"
  >
    <template #title> {{ tooltipTilte }} </template>
    <template #subtitle> {{ t('bonuses.depositBonus') }} </template>
    <template v-if="isStepBonus">
      <DepositBonusStepTooltip
        v-for="bonus in depositBonus.steps"
        :key="bonus.id"
        :bonus="bonus"
      />
    </template>
    <DepositBonusSingleTooltip v-else :bonus="depositBonus" />
  </BaseTooltipModal>
</template>

<script setup lang="ts">
import { omit } from '@st/utils'
import { useDepositBonusesStore } from '../../stores/useDepositBonusesStore'
import BaseTooltipModal from '../TooltipModal/BaseTooltipModal.vue'
import { useGetTitleByLevel } from '../../composables/useGetTitleByLevel'
import { useDepositBonus } from '../../composables/useDepositBonus'
import DepositBonusSingleTooltip from './parts/DepositBonusSingleTooltip.vue'
import DepositBonusStepTooltip from './parts/DepositBonusStepTooltip.vue'

const { id } = defineProps<{
  id: string | number
}>()

const { t } = useI18n()
const readMoreUrl = usePublicFileUrl('publicFileBonusForDepositTerms')

const { allDepositPrograms } = storeToRefs(useDepositBonusesStore())
const depositBonus = computed(
  () => allDepositPrograms.value.filter((item) => item.id === Number(id))[0],
)
const { filteredMultiBonuses, isStepBonus } = useDepositBonus(depositBonus)

const route = useRoute()
const router = useRouter()

const { getTitle } = useGetTitleByLevel()
const tooltipTilte = computed(() => {
  const titles = filteredMultiBonuses.value.map(
    (item) => `${item?.text ?? ''} ${item?.amountWithCurrency ?? ''}`,
  )
  const titlesCombinations = titles.join(' + ')
  const title = isStepBonus.value ? t('bonuses.bonus') : titlesCombinations

  return depositBonus.value.level
    ? getTitle(title, depositBonus.value.level)
    : `${title} ${t('bonuses.depositNTitle')}`
})

function handleClose() {
  router.replace({ query: omit(route.query, ['modal']) })
}
</script>
